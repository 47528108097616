/* Modal container */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.event-form {
  display: grid;
  grid-template-columns: 120px 1fr; /* Labels and inputs */
  gap: 8px 16px; /* Adjust spacing */
  align-items: center;
  max-width: 500px;
  /* margin: 0 auto; */
}

.form-row {
  display: contents;
}

label {
  text-align: left;
  font-weight: 500;
  margin: 0;
}

/* Common Styling for Input and Select */
.input, select {
  height: 32px; /* Ensure same height */
  width: 200px; /* Same width */

  margin-bottom: 7px;
  padding: 4px 8px; /* Inner padding */
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box; /* Ensure padding and border don't affect size */
  font: inherit; /* Ensure consistent font across elements */
}

/* Custom Styling for Select to Match Input */
select {
  -webkit-appearance: none; /* Remove default styling on WebKit browsers */
  -moz-appearance: none; /* Remove default styling on Firefox */
  appearance: none; /* Remove default styling */
  background-color: white; /* Ensure background matches input fields */
  padding-right: 30px; /* Ensure space for dropdown arrow */
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 24 24' fill='none' stroke='%23000' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M6 9l6 6 6-6'%3E%3C/path%3E%3C/svg%3E"); 
  background-repeat: no-repeat;
  background-position: right 8px center;
  background-size: 12px;
}



.create-rotation-btn {
  margin-top: 10px;
}

.add-event-btn {
  margin-top: 10px;
  margin-right: 10px;
  background-color: #db4437;
  color: #fff;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.add-event-btn:hover {
  background-color: #a72418;
}

.add-event-btn:disabled {
  background-color: #e78d85;
  cursor: not-allowed;
}

/* Modal content */
.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  width: 400px;
  max-width: 100%;
  z-index: 1001;
}

/* Event list */
.event-list {
  display: flex;
  flex-direction: column;
  gap: 10px; /* Space between event boxes */
}

/* Event box style to match calendar events */
.event-box {
  padding: 10px;
  border-radius: 5px;
  color: white;
  font-size: 14px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/* Hover effect for better UX */
.event-box:hover {
  opacity: 0.9;
}
/* Container for the calendar and legend */
.calendar-layout {
  display: flex;
  justify-content: space-between;  /* Creates space between the calendar and legend */
  width: 100%;  /* Make sure the container takes the full width */
  gap: 2px;  /* Adds space between the calendar and legend */
  flex-wrap: wrap;  /* Allows the layout to wrap when the screen is narrow */
}

/* Calendar container */
.calendar-container {
  flex: 1;
  max-width: 90%;  /* Adjust the calendar width to make space for the legend */
}

/* Legend container */
.legend-container {
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

/* Media query for smaller screens */
@media (max-width: 900px) {
  .calendar-container {
    max-width: 100%;  /* Make the calendar take full width */
  }

  .legend-container {
    width: 100%;  /* Make the legend take full width */
    justify-content: flex-start;
  }
}
