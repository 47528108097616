.sidebar {
  width: 400px;
  background-color: #f2f2f2;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.sidebar p {
  font-weight: bold;
}

.sidebar ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.sidebar li {
  margin-bottom: 10px;
}


.sidebar a {
  text-decoration: underline;
  color: #333;
}

.sidebar a:hover {
  color: #a72418;
  font-weight: bold;
}


.scrollable-container-carpool {
  max-height: 200px; /* Adjust the height as needed */
  overflow-y: auto;
}