.title-row {
  display: flex;
  align-items: center;
  justify-content: center; 
}

.title-row h1 {
  text-align: center; /* Keeps the title centered within its space */
}

/* ----- */

.hyperlink {
  margin-top: 5px;
  text-decoration: underline;
  font-size: 14px;
  color: #db4437;
  cursor: pointer;
}

.hyperlink:hover {
  color: #a72418;
}


.center {
  text-align: center;
}

.reassign-container {
  border: 2px solid black;
  margin: 10px;
  padding: 10px;
}

.scrollable-container {
  max-height: 200px; /* Adjust the height as needed */
  width: 500px;
  overflow-y: auto;
  border: 1px solid #ccc; /* Optional: Add a border for better visibility */
  padding-right: 10px; /* Prevent content from being hidden behind the scrollbar */
  position: relative;
}

.scrollable-container::-webkit-scrollbar {
  width: 12px;
}

.scrollable-container::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.scrollable-container::-webkit-scrollbar-thumb {
  background: #888;
}

.scrollable-container::-webkit-scrollbar-thumb:hover {
  background: #555;
}