#carpool-navbar {
  background-color: white;
  /* padding: 5px; */
  margin-bottom: 0px;
  /* margin-top: 10px; */
  width: 150px;
  height: 30px;
  border-bottom: 1px solid black;
}

#carpool-navbar p {
  font-size: 20px;
  font-weight: bold;
  margin: 0;
}

#carpool-navbar ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
}

#carpool-navbar li {
  margin-right: 10px;
  text-decoration: none;
  color: #333;
  padding: 10px;
  cursor: pointer;
  border-radius: 5px;
}

#carpool-navbar li:hover {
  background-color: #ddd;
}

#carpool-navbar ul li.active-tab {
  text-decoration: underline;
}

#carpool-navbar ul li p {
  font-size: 7px;
}