/* styles.css */

/* .popup { */
  /* position: fixed; */
  /* top: 0; */
  /* left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(5px); /* This applies the blur effect */
/* } */

/* .popup-inner {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  text-align: center; */
  /* box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); */
/* }*/

/* styles.css */
.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure this is higher than the z-index of any calendar elements */
}

.popup-inner {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  text-align: center;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1001; /* Ensure this is higher than the z-index of any calendar elements */
}
