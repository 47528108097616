/* Legend container styling
.legend-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 20px;
}
*/

/* Individual legend item */
.legend-item {
  display: flex;
  align-items: center;
}

/* Legend color box */
.legend-color {
  width: 20px;
  height: 20px;
  margin-right: 10px;
  border-radius: 3px;
  border: 1px solid #ccc;
}

.add-family-link {
  margin-top: 5px;
  text-decoration: underline;
  font-size: 14px;
  color: #db4437;
  cursor: pointer;
}

.add-family-link:hover {
  color: #a72418;
}

.disable-add-family {
  margin-top: 5px;
  text-decoration: underline;
  font-size: 14px;
  color: #db4437;
  cursor: not-allowed;
}

/* Legend label */
.legend-label {
  font-size: 14px;
  color: #333;
}
