.share-carpool {
  position: relative;
  display: inline-block;
}

.icon-button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 22px;
  color: #db4437;
  transition: color 0.3s;
}

.icon-button:hover {
  color: #a72418;
}

.copy-popup {
  position: absolute;
  top: 40px;
  left: 0;
  width: 300px;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  padding: 15px;
  z-index: 1000;  
}

.link-box {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.link-input {
  flex: 1;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #f9f9f9;
}

.copy-button {
  margin-bottom: 7px;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 18px;
  color: #555;
  transition: color 0.3s;
}

.copy-button:hover {
  color: #000;
}

.copied-message {
  margin-top: 10px;
  font-size: 14px;
  color: green;
}
