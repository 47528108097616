form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

p {
  margin: 10px 0;
}

input[type="text"],
input[type="date"] {
  padding: 5px;
  margin-bottom: 10px;
  width: 200px;
}

button[type="submit"] {
  padding: 10px 20px;
  background-color: #db4437;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

button[type="submit"]:hover {
  background-color: #a72418;
}

button[type="submit"]:disabled {
  background-color: #e78d85;;
  cursor: not-allowed;
}