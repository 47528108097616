h1 {
  font-size: 30px;
  color: #333;
  text-align: center;
  margin-bottom: 20px;
}

body {
  font-family: "Optima", sans-serif;
}

.dashboard {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  padding-top: 30px;
}

.primary-btn {
  padding: 10px 20px;
  background-color: #db4437;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.primary-btn:hover {
  background-color: #a72418;
}

.primary-btn:disabled {
  background-color: #e78d85;
  cursor: not-allowed;
}

.secondary-btn {
  padding: 8px 15px;
  background-color: #fff3f3;
  color: #333;
  /* border: 1px solid #ccc; */
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.secondary-btn:hover {
  background-color: #e1e1e1;
}

.secondary-btn:disabled {
  background-color: #f9f9f9;
  cursor: not-allowed;
  color: #333;
}

#join-carpool-container {
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: center;
  align-items: center;
}

#join-carpool-container label, #join-carpool-container input {
  margin-top: 10px;
}

.requested-msg {
  color: #db4437;
  font-weight: bold;
}

.container-border {
  border: 1px solid #333;
  border-radius: 5px;
  margin: 10px;
  padding: 10px;
}