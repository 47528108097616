.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
}

#profile-img {
  width: 300px;
  height: 300px;
  margin-bottom: 20px;
}

#signin-google-button {
  width: 200px;
  height: 50px;
  background-color: #db4437;
  padding: 10px;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

label {
  font-size: 18px;
  margin-bottom: 10px;
}

#display-name-input {
  margin-top: 5px;
  width: 200px;
  height: 30px;
  margin-bottom: 10px;
  padding: 5px;
}

#signout-btn {
  width: 100px;
  height: 40px;
  background-color: #db4437;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

#signout-btn:hover {
  background-color: #a72418;
}