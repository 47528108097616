nav {
  background-color: #f2f2f2;
  padding: 10px;
}

nav p {
  font-size: 20px;
  font-weight: bold;
  margin: 0;
}


nav ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
}

nav li {
  margin-right: 10px;
}

nav a {
  text-decoration: none;
  color: #333;
  padding: 5px;
  border-radius: 5px;
}

nav a:hover {
  background-color: #ddd;
}

nav ul li a.active-link {
  text-decoration: underline;
}